// import React from 'react';
// import { Card } from 'antd';
// import PropTypes from 'prop-types';
// import IconDelete from '../assets/images/Delete.png';
// import IconRightArrow from '../assets/images/icon-rightArrow.png';

// const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete  }) => {

//   const getReturnStatusText = (status) => {
//     if (status === "Fully") {
//       return "Returned";
//     } else if (status === "Partially") {
//       return "Partially Returned";
//     } else {
//       return "";  
//     }
//   };

//   const returnStatusText = getReturnStatusText(order.return_order_status);
 
//   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;
 
//   const handleClickOrder = () => {
//     onClick(order);
//   };
//   const handleDeleteOrder = (e) => {
//     e.stopPropagation();  
//     onDelete(order.name);
//   };

//   return (
//     <Card className={boxClassName} onClick={handleClickOrder}>
//       <p>
//         <span>ID: {order.name}</span>
//         <span className="light-text">$ {order.total ? order.total.toFixed(2) : '0.00'}</span>
//       </p>
//       <p className="order-date">{order.creation}</p>
//       <p className='status-row'>
//        {order.contact_mobile && <span>
//           {order.contact_mobile} <span className="light-text">| {order.contact_name}</span>
//         </span>
//         }
//         <span>
//           {showDelete && (
            
//               <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />
          
//           )}
//           {showMoveToCart && (
            
//               <img src={IconRightArrow} alt="Move to Cart" />
            
//           )}
//           {showPayNow && <a href="#"> Pay Now</a>}
//           {returnStatusText && <p className="return-status">{returnStatusText}</p>}
//         </span>
//       </p>
//     </Card>
//   );
// };

// OrderBox.propTypes = {
//   order: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     total: PropTypes.number.isRequired,
//     creation: PropTypes.string.isRequired,
//     contact_mobile: PropTypes.string.isRequired,
//     contact_name: PropTypes.string.isRequired,
//     return_order_status: PropTypes.string,
//   }).isRequired,
//   showPayNow: PropTypes.bool,
//   showDelete: PropTypes.bool,
//   showMoveToCart: PropTypes.bool,
//   onClick: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
// };


// export default OrderBox;


import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconDelete from '../assets/images/Delete.png';
import IconRightArrow from '../assets/images/icon-rightArrow.png';
import Print from '../assets/images/print.svg';

const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete, selectedOrder, indicator }) => {
  const { t } = useTranslation();

  const getReturnStatusText = (status,mode_of_payment) => {
    if (status === 'Return') {
      return t('orderBox.returned');
    } 
    else if (status === 'Paid') {
      return t('Paid');
    } else if(mode_of_payment==="Credit"){
      return t('Credit');
    }
      else {  
      return '';
    }
  };

  // console.log("Order Box ", order);

  const returnStatusText = getReturnStatusText(order.status,order.mode_of_payment);

  const boxClassName = `col-md-3 order-box ${order.return_order_status === 'Fully' ? 'returned' : ''} ${order.return_order_status === 'Partially' ? 'returned' : ''}`;

  const handleClickOrder = () => {
    onClick(order);
  };

  const handleDeleteOrder = (e) => {
    e.stopPropagation();
    onDelete(order.name);
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    const url = '/receipt';
    const state = { response: order };
    const encodedState = encodeURIComponent(JSON.stringify(state));
    window.open(`${url}?state=${encodedState}`, '_blank');
  };

  return (
    <>
      {indicator ? (
        <Card className={boxClassName} onClick={handleClickOrder}>
          <p>
            <span>{t('orderBox.id')}: {order.sales}</span>
            <span className="light-text">
              $ {order.grand_total ? (order.grand_total - order.loyalty_amount).toFixed(2) : '0.00'}
            </span>
          </p>
          <p className="order-date">
            {order.transaction_date}, {order.transaction_time}
          </p>
          <p className="status-row">
            {indicator && order.customer_name && (
              <div className="printdiv">
                <div>
                  {order.contact_mobile} <span className="light-text">| {order.customer_name}</span>
                </div>
                <img src={Print} onClick={handlePrint} className="printbtn" alt={t('orderBox.print')} />
              </div>
            )}
            <span>
              {showDelete && (
                <img src={IconDelete} alt={t('orderBox.delete')} onClick={handleDeleteOrder} />
              )}
              {showMoveToCart && (
                <img src={IconRightArrow} alt={t('orderBox.moveToCart')} />
              )}
              {showPayNow && <a href="#">{t('orderBox.payNow')}</a>}
              {returnStatusText && <p className="return-status">{returnStatusText}</p>}
            </span>
          </p>
        </Card>
      ) : (
        <Card className={boxClassName} onClick={handleClickOrder}>
          <p>
            <span>{t('orderBox.id')}: {order.name}</span>
            <span className="light-text">
              $ {order.subtotal ? order.subtotal.toFixed(2) : '0.00'}
            </span>
          </p>
          <p className="order-date">
            {order.creation}
          </p>
          <p className="status-row">
            <div className="printdiv">
              <div>
                {order.customer.mobile_no} <span className="light-text">| {order.customer.customer_name}</span>
              </div>
            </div>
            <span>
              {showDelete && (
                <img src={IconDelete} alt={t('orderBox.delete')} onClick={handleDeleteOrder} />
              )}
              {showMoveToCart && (
                <img src={IconRightArrow} alt={t('orderBox.moveToCart')} />
              )}
            </span>
          </p>
        </Card>
      )}
    </>
  );
};

OrderBox.propTypes = {
  order: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    creation: PropTypes.string.isRequired,
    contact_mobile: PropTypes.string.isRequired,
    contact_name: PropTypes.string.isRequired,
    return_order_status: PropTypes.string,
  }).isRequired,
  showPayNow: PropTypes.bool,
  showDelete: PropTypes.bool,
  showMoveToCart: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OrderBox;

// 5.9.2024
// import React from 'react';
// import { Card } from 'antd';
// import PropTypes from 'prop-types';
// import IconDelete from '../assets/images/Delete.png';
// import IconRightArrow from '../assets/images/icon-rightArrow.png';
// import Print from '../assets/images/print.svg';


// const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete,selectedOrder,indicator }) => {
  


//   // console.log("Parked Order",order);
  

//   const getReturnStatusText = (status) => {
//     if (status === "Fully") {
//       return "Returned";
//     } else if (status === "Partially") {
//       return "Partially Returned";
//     } else {
//       return "";
//     }
//   };

//   const returnStatusText = getReturnStatusText(order.return_order_status);

//   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;

//   const handleClickOrder = () => {
//     onClick(order);
//   };
//   const handleDeleteOrder = (e) => {
//     e.stopPropagation();
//     onDelete(order.name);
//   };

//   // const formatDate = (dateString) => {
//   //   const date = new Date(dateString);
//   //   const day = String(date.getDate()).padStart(2, '0');
//   //   const month = String(date.getMonth() + 1).padStart(2, '0');
//   //   const year = date.getFullYear();
//   //   const hours = String(date.getHours()).padStart(2, '0');
//   //   const minutes = String(date.getMinutes()).padStart(2, '0');
//   //   return `${day}-${month}-${year} ${hours}:${minutes}`;
//   // };

//   // const formattedDate = formatDate(order.creation);
//   // console.log("4654321354324354324", order.creation);
//   const handlePrint=(e)=>{
//     e.stopPropagation();
//     const url = '/receipt';
//     const state = { response: order };
//     const encodedState = encodeURIComponent(JSON.stringify(state));
//     window.open(`${url}?state=${encodedState}`, '_blank');
//   }

//   return (
//     <>
//     {indicator ?  <Card className={boxClassName} onClick={handleClickOrder}>
//       <p>
//         <span>ID: {order.name}</span>
//         <span className="light-text">$ {order.grand_total ? (order.grand_total - order.loyalty_amount).toFixed(2) : '0.00'}</span>
//       </p>
//       <p className="order-date"> {order.transaction_date}, {order.transaction_time}</p>
//       <p className='status-row'>

//         {indicator && order.customer_name && <div className='printdiv'>

//           <div>{order.contact_mobile} <span className="light-text">| {order.customer_name}</span></div>
//           <img src={Print}  onClick={handlePrint}  className='printbtn' alt="Print" />
//           {/* <button onClick={handlePrint} className='printbtn'>{Print}</button> */}
//         </div>
        
//         }
       
//         <span>
//           {showDelete && (

//             <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />

//           )}
//           {showMoveToCart && (

//             <img src={IconRightArrow} alt="Move to Cart" />

//           )}
//           {showPayNow && <a href="#"> Pay Now</a>}
//           {returnStatusText && <p className="return-status">{returnStatusText}</p>}
//         </span>
//       </p>
//     </Card>
//       :
//       <Card className={boxClassName} onClick={handleClickOrder}>
//       <p>
//         <span>ID: {order.name}</span>
//         <span className="light-text">$ {order.subtotal ? order.subtotal.toFixed(2) : '0.00'}</span>
//       </p>
//       <p className="order-date"> {order.creation}</p>
//       <p className='status-row'>

//         <div className='printdiv'>

//           <div>{order.customer.mobile_no} <span className="light-text">| {order.customer.customer_name}</span></div>
        
//         </div>
        
        
       
//         <span>
//           {showDelete && (

//             <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />

//           )}
//           {showMoveToCart && (

//             <img src={IconRightArrow} alt="Move to Cart" />

//           )}
//           {/* {showPayNow && <a href="#"> Pay Now</a>} */}
//           {/* {returnStatusText && <p className="return-status">{returnStatusText}</p>} */}
//         </span>
//       </p>
//     </Card>
//      }
//     </>
//   );
// };

// OrderBox.propTypes = {
//   order: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     total: PropTypes.number.isRequired,
//     creation: PropTypes.string.isRequired,
//     contact_mobile: PropTypes.string.isRequired,
//     contact_name: PropTypes.string.isRequired,
//     return_order_status: PropTypes.string,
//   }).isRequired,
//   showPayNow: PropTypes.bool,
//   showDelete: PropTypes.bool,
//   showMoveToCart: PropTypes.bool,
//   onClick: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
// };


// export default OrderBox;




















































// // import React from 'react';
// // import { Card } from 'antd';
// // import PropTypes from 'prop-types';
// // import IconDelete from '../assets/images/Delete.png';
// // import IconRightArrow from '../assets/images/icon-rightArrow.png';

// // const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete  }) => {

// //   const getReturnStatusText = (status) => {
// //     if (status === "Fully") {
// //       return "Returned";
// //     } else if (status === "Partially") {
// //       return "Partially Returned";
// //     } else {
// //       return "";  
// //     }
// //   };

// //   const returnStatusText = getReturnStatusText(order.return_order_status);
 
// //   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;
 
// //   const handleClickOrder = () => {
// //     onClick(order);
// //   };
// //   const handleDeleteOrder = (e) => {
// //     e.stopPropagation();  
// //     onDelete(order.name);
// //   };

// //   return (
// //     <Card className={boxClassName} onClick={handleClickOrder}>
// //       <p>
// //         <span>ID: {order.name}</span>
// //         <span className="light-text">$ {order.total ? order.total.toFixed(2) : '0.00'}</span>
// //       </p>
// //       <p className="order-date">{order.creation}</p>
// //       <p className='status-row'>
// //        {order.contact_mobile && <span>
// //           {order.contact_mobile} <span className="light-text">| {order.contact_name}</span>
// //         </span>
// //         }
// //         <span>
// //           {showDelete && (
            
// //               <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />
          
// //           )}
// //           {showMoveToCart && (
            
// //               <img src={IconRightArrow} alt="Move to Cart" />
            
// //           )}
// //           {showPayNow && <a href="#"> Pay Now</a>}
// //           {returnStatusText && <p className="return-status">{returnStatusText}</p>}
// //         </span>
// //       </p>
// //     </Card>
// //   );
// // };

// // OrderBox.propTypes = {
// //   order: PropTypes.shape({
// //     name: PropTypes.string.isRequired,
// //     total: PropTypes.number.isRequired,
// //     creation: PropTypes.string.isRequired,
// //     contact_mobile: PropTypes.string.isRequired,
// //     contact_name: PropTypes.string.isRequired,
// //     return_order_status: PropTypes.string,
// //   }).isRequired,
// //   showPayNow: PropTypes.bool,
// //   showDelete: PropTypes.bool,
// //   showMoveToCart: PropTypes.bool,
// //   onClick: PropTypes.func.isRequired,
// //   onDelete: PropTypes.func.isRequired,
// // };


// // export default OrderBox;



// import React from 'react';
// import { Card } from 'antd';
// import PropTypes from 'prop-types';
// import IconDelete from '../assets/images/Delete.png';
// import IconRightArrow from '../assets/images/icon-rightArrow.png';


// const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete,selectedOrder }) => {
  



//   const getReturnStatusText = (status) => {
//     if (status === "Fully") {
//       return "Returned";
//     } else if (status === "Partially") {
//       return "Partially Returned";
//     } else {
//       return "";
//     }
//   };

//   const returnStatusText = getReturnStatusText(order.return_order_status);

//   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;

//   const handleClickOrder = () => {
//     onClick(order);
//   };
//   const handleDeleteOrder = (e) => {
//     e.stopPropagation();
//     onDelete(order.name);
//   };

//   // const formatDate = (dateString) => {
//   //   const date = new Date(dateString);
//   //   const day = String(date.getDate()).padStart(2, '0');
//   //   const month = String(date.getMonth() + 1).padStart(2, '0');
//   //   const year = date.getFullYear();
//   //   const hours = String(date.getHours()).padStart(2, '0');
//   //   const minutes = String(date.getMinutes()).padStart(2, '0');
//   //   return `${day}-${month}-${year} ${hours}:${minutes}`;
//   // };

//   // const formattedDate = formatDate(order.creation);
//   // console.log("4654321354324354324", order.creation);
//   // const handlePrint=(e)=>{
//   //   e.stopPropagation();
//   //   // const obj = {
//   //   //   key1: "val1",
//   //   //   key2: "val2",
//   //   //   key3: "val3",
//   //   // }
//   //   const data = encodeURIComponent(JSON.stringify(order));
//   //   console.log(data,"Data of 137")
//   //   window.open(`/receipt?data=${data}`, '_blank');

//   // }
//   const handlePrint=(e)=>{
//     e.stopPropagation();
//     const url = '/receipt';
//     const state = { response: order };
//     const encodedState = encodeURIComponent(JSON.stringify(state));
//     window.open(`${url}?state=${encodedState}`, '_blank');
//   }

//   return (
//     <Card className={boxClassName} onClick={handleClickOrder}>
//       <p>
//         <span>ID: {order.name}</span>
//         <span className="light-text">$ {order.total ? order.total.toFixed(2) : '0.00'}</span>
//       </p>
//       <p className="order-date"> {order.transaction_date}, {order.transaction_time}</p>
//       <p className='status-row'>

//         {order.customer_name && <div className='printdiv'>

//           <div>{order.contact_mobile} <span className="light-text">| {order.customer_name}</span></div>
//           <button onClick={handlePrint} className='printbtn'>Print</button>
//         </div>
        
//         }
       
//         <span>
//           {showDelete && (

//             <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />

//           )}
//           {showMoveToCart && (

//             <img src={IconRightArrow} alt="Move to Cart" />

//           )}
//           {showPayNow && <a href="#"> Pay Now</a>}
//           {returnStatusText && <p className="return-status">{returnStatusText}</p>}
//         </span>
//       </p>
//     </Card>
//   );
// };

// OrderBox.propTypes = {
//   order: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     total: PropTypes.number.isRequired,
//     creation: PropTypes.string.isRequired,
//     contact_mobile: PropTypes.string.isRequired,
//     contact_name: PropTypes.string.isRequired,
//     return_order_status: PropTypes.string,
//   }).isRequired,
//   showPayNow: PropTypes.bool,
//   showDelete: PropTypes.bool,
//   showMoveToCart: PropTypes.bool,
//   onClick: PropTypes.func.isRequired,
//   onDelete: PropTypes.func.isRequired,
// };


// export default OrderBox;


// //  ---------------------------------------------------------------------------------------------------------------------------------- Ayush Ones 
// // import React, { useRef } from 'react';
// // import { Card, notification } from 'antd';
// // import PropTypes from 'prop-types';
// // import IconDelete from '../assets/images/Delete.png';
// // import IconRightArrow from '../assets/images/icon-rightArrow.png';
// // import IconEmailButton from '../assets/images/email.png';
// // import IconPrintButton from '../assets/images/print.png';
// // // import { sendMailToUser } from "../modules/LandingPage";
// // import PrintFormateOfOrder from "./Receipt";

// // const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete,indicator }) => {
// //   const getReturnStatusText = (status) => {
// //     if (status === "Fully") {
// //       return "Returned";
// //     } else if (status === "Partially") {
// //       return "Partially Returned";
// //     } else {
// //       return "";  
// //     }
// //   };

// //   const returnStatusText = getReturnStatusText(order.return_order_status);
// //   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;
  
// //   const handleClickOrder = () => {
// //     onClick(order);
// //   };
// //   const handleDeleteOrder = (e) => {
// //     e.stopPropagation();  
// //     onDelete(order.name);
// //   };

// //   const formatDate = (dateString) => {
// //     const date = new Date(dateString);
// //     const day = String(date.getDate()).padStart(2, '0');
// //     const month = String(date.getMonth() + 1).padStart(2, '0');
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, '0');
// //     const minutes = String(date.getMinutes()).padStart(2, '0');
// //     return `${day}-${month}-${year} ${hours}:${minutes}`;
// //   };

// //   const formattedDate = formatDate(order.creation);
  
// //   // const emailClickHandler = async (e) => {
// //   //   e.stopPropagation();
// //   //   try {
// //   //     const apiResponse = await sendMailToUser(order.name);
// //   //     if (apiResponse && apiResponse.success_key === 1) {
// //   //       notification.success({
// //   //         message: "Email Sent Successfully",
// //   //         description: `An email has been sent to the user associated with order ${order.name}.`,
// //   //       });
// //   //     } else {
// //   //       notification.error({
// //   //         message: "Email Sending Failed",
// //   //         description: `Failed to send email: ${apiResponse.message}`,
// //   //       });
// //   //     }
// //   //   } catch (error) {
// //   //     notification.error({
// //   //       message: "Server Error",
// //   //       description: `An unexpected error occurred while sending the email: ${error.message}`,
// //   //     });
// //   //   }
// //   // };

// //   const printRef = useRef();

// //   const handleButtonClick = (e) => {
// //     e.stopPropagation();
// //     if (printRef.current) {
// //       printRef.current.handlePrint();
// //     }
// //   };

// //   return (
// //     <Card className={boxClassName} onClick={handleClickOrder}>
// //       <p>
// //         <span>ID: {order.name}</span>
// //         {returnStatusText && <p className="return-status">{returnStatusText}</p>}
// //       </p>

// //  <span className='amountAndDate'>
// //     <span className="light-text">$ {order.total ? order.total.toFixed(2) : '0.00'} <span className="order-date">| {formattedDate}
      
// //     </span>
// //      </span>
 
// //     </span>
// //       <p className='status-row'>
// //         <span>
// //           {order.contact_mobile} <span className="light-text">| {order.contact_name}</span>
// //         </span>
// //         <span>
// //           {showDelete && (
// //             <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />
// //           )}
// //           {showMoveToCart && (
// //             <img src={IconRightArrow} alt="Move to Cart" />
// //           )}
// //           {showPayNow && <a href="#"> Pay Now</a>}
        
// //         </span>
// //       </p>
// //      {indicator && <div className="button-container">
// //         <button className="icon-button" onClick={handleButtonClick}>
// //           <img src={IconPrintButton} alt="Print Button" />
// //         </button>
// //         <PrintFormateOfOrder ref={printRef} doc={order} />
// //         {/* <button className="icon-button" onClick={emailClickHandler}>
// //           <img src={IconEmailButton} alt="Email Button" />
// //         </button> */}
// //       </div>
// //      }
// //     </Card>
// //   );
// // };

// // OrderBox.propTypes = {
// //   order: PropTypes.shape({
// //     name: PropTypes.string.isRequired,
// //     total: PropTypes.number.isRequired,
// //     creation: PropTypes.string.isRequired,
// //     contact_mobile: PropTypes.string.isRequired,
// //     contact_name: PropTypes.string.isRequired,
// //     return_order_status: PropTypes.string,
// //   }).isRequired,
// //   showPayNow: PropTypes.bool,
// //   showDelete: PropTypes.bool,
// //   showMoveToCart: PropTypes.bool,
// //   onClick: PropTypes.func.isRequired,
// //   onDelete: PropTypes.func.isRequired,
// // };

// // export default OrderBox;

// // ---------------------------------------------------------------------------------   Mine 

// // import React from 'react';
// // import { Card } from 'antd';
// // import PropTypes from 'prop-types';
// // import IconDelete from '../assets/images/Delete.png';
// // import IconRightArrow from '../assets/images/icon-rightArrow.png';

// // const OrderBox = ({ order, showPayNow, showDelete, showMoveToCart, onClick, onDelete, selectedOrder }) => {
// //   const getReturnStatusText = (status) => {
// //     if (status === "Fully") {
// //       return "Returned";
// //     } else if (status === "Partially") {
// //       return "Partially Returned";
// //     } else {
// //       return "";
// //     }
// //   };

// //   const returnStatusText = getReturnStatusText(order.return_order_status);

// //   const boxClassName = `col-md-3 order-box ${order.return_order_status === "Fully" ? "returned" : ""} ${order.return_order_status === "Partially" ? "returned" : ""}`;

// //   const handleClickOrder = () => {
// //     onClick(order);
// //   };
// //   const handleDeleteOrder = (e) => {
// //     e.stopPropagation();
// //     onDelete(order.name);
// //   };

// //   const handlePrint = (e) => {
// //     e.stopPropagation();
// //     const obj = {
// //       key1: "val1",
// //       key2: "val2",
// //       key3: "val3",
// //     }
// //     const data = encodeURIComponent(JSON.stringify(obj));
// //     // window.open(`/receipt?data=${data}`, '_blank');
// //     window.open(`/reciept?data=${data}`, '_blank');
// //   }

// //   // const formatDate = (dateString) => {
// //   //   const date = new Date(dateString);
// //   //   const day = String(date.getDate()).padStart(2, '0');
// //   //   const month = String(date.getMonth() + 1).padStart(2, '0');
// //   //   const year = date.getFullYear();
// //   //   const hours = String(date.getHours()).padStart(2, '0');
// //   //   const minutes = String(date.getMinutes()).padStart(2, '0');
// //   //   return `${day}-${month}-${year} ${hours}:${minutes}`;
// //   // };

// //   // const formattedDate = formatDate(order.creation);
// //   // console.log("4654321354324354324", order.creation);

// //   return (
// //     <Card className={boxClassName} onClick={handleClickOrder}>
// //       <p>
// //         <span>ID: {order.name}</span>
// //         <span className="light-text">$ {order.grand_total ? order.grand_total.toFixed(2) : '0.00'}</span>
// //       </p>
// //       <p className="order-date"> {order.transaction_date}, {order.transaction_time}</p>
// //       <p className='status-row'>
// //         {/* 
// //         {order.customer_name && <span>
// //           {order.contact_mobile} <span className="light-text">| {order.customer_name}</span>
// //         </span>} */}


// //         {order.customer_name && <div className='printdiv'>

// //           <div>{order.contact_mobile} <span className="light-text">| {order.customer_name}</span></div>
// //           <button onClick={handlePrint} className='printbtn'>Print</button>
// //         </div>

// //         }
// //         {/* <span>
// //           {order.contact_mobile} <span className="light-text">| {order.contact_name}</span>
// //         </span> */}
// //         <span>
// //           {showDelete && (

// //             <img src={IconDelete} alt="Delete" onClick={handleDeleteOrder} />

// //           )}
// //           {showMoveToCart && (

// //             <img src={IconRightArrow} alt="Move to Cart" />

// //           )}
// //           {showPayNow && <a href="#"> Pay Now</a>}
// //           {returnStatusText && <p className="return-status">{returnStatusText}</p>}
// //         </span>
// //       </p>
// //     </Card>
// //   );
// // };

// // OrderBox.propTypes = {
// //   order: PropTypes.shape({
// //     name: PropTypes.string.isRequired,
// //     total: PropTypes.number.isRequired,
// //     creation: PropTypes.string.isRequired,
// //     contact_mobile: PropTypes.string.isRequired,
// //     contact_name: PropTypes.string.isRequired,
// //     return_order_status: PropTypes.string,
// //   }).isRequired,
// //   showPayNow: PropTypes.bool,
// //   showDelete: PropTypes.bool,
// //   showMoveToCart: PropTypes.bool,
// //   onClick: PropTypes.func.isRequired,
// //   onDelete: PropTypes.func.isRequired,
// // };


// // export default OrderBox;
