// import React, { useState, useEffect, useContext } from "react";
// import { Spin, Tabs } from "antd";
// import Layout from "../components/Layout";
// import OrderBox from "../components/OrderBox";
// import SearchIcon from "../assets/images/icon-search.png";
// import {
//   fetchSalesOrderList,
//   fetchSearchSalesOrderList,
// } from "../modules/LandingPage";
// import OrderDetailModal from "../components/OrderDetailModal";
// import { CartContext } from "../common/CartContext";
// import Pagination from "../components/PaginationforOrdersPage";

// const { TabPane } = Tabs;

// const OrderPage = ({ hubManagerEmail }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);

//   const [currentPageParked, setCurrentPageParked] = useState(1);
//   const [currentPageComplete, setCurrentPageComplete] = useState(1);
//   const [ordersPerPage, setOrdersPerPage] = useState(10);
//   const [Data, setData] = useState([])
//   const [SearchData, setSearchData] = useState([])
//   const [PageCount, setPageCount] = useState(1);
//   const { cartItems, setCartItems,  red, setred } = useContext(CartContext);
//   const [isReturnSuccess, setIsReturnSuccess] = useState(false);
//   const [isReturnPartial, setIsReturnPartial] = useState(false);

//   const GetSalesOrderList = async (PageCount) => {
//     try {
//       const { email } = JSON.parse(localStorage.getItem("user"));
//       const orderList = await fetchSalesOrderList(email, PageCount);
//       setData(orderList)
//       setLoading(false);

//     } catch (error) {
//       console.log("Error occur", error);
//       setLoading(false);

//     }
//   }

//   useEffect(() => {
//     GetSalesOrderList(PageCount)
//   }, [PageCount, hubManagerEmail,red])

//   const handlePrevBtn = async () => {
//     setPageCount((prev) => prev - 1)
//   }
//   const handleNextBtn = async () => {
//     setPageCount((prev) => prev + 1)
//   }

 

//   const fetchParkedOrders = () => {
//     const parkedOrders = JSON.parse(localStorage.getItem("parkedOrders")) || [];
//     return parkedOrders.map((order) => ({
//       ...order,
//       customer_name: order.customer_name || "Guest",
//       contact_mobile: order.contact_mobile || "N/A",
//     }));
//   };

//   const User = JSON.parse(localStorage.getItem("user"));

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const orderList = await fetchSalesOrderList(
//           User.email,
//           currentPageComplete
//         );
//         const parkedOrders = fetchParkedOrders();
//         setOrders((prevOrders) => [
//           ...prevOrders,
//           ...orderList,
//           ...parkedOrders,
//         ]);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//         setLoading(false);
//       }
//     };
//     fetchOrders();
//   }, [hubManagerEmail, currentPageComplete,isReturnSuccess,isReturnPartial]);

//   const handlePageChangeParked = (page) => {
//     setCurrentPageParked(page);
//   };

//   const handlePageChangeComplete = (page) => {
//     setCurrentPageComplete(page);
//   };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//     setCurrentPageParked(1); // Reset to the first page when searching
//     setPageCount(1)
//   };

//   const handleOrderClick = (order) => {
//     setSelectedOrder(order);
//     setIsModalVisible(true);
//   };

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//     setSelectedOrder(null);
//   };

//   const moveOrderToCart = (order) => {
//     // Remove the order from parked orders
//     const updatedParkedOrders = fetchParkedOrders().filter(
//       (o) => o.name !== order.name
//     );
//     localStorage.setItem("parkedOrders", JSON.stringify(updatedParkedOrders));

//     // Update the orders state to remove the moved order
//     const updatedOrders = orders.filter((o) => o.name !== order.name);
//     setOrders(updatedOrders);

//     // Add the order items to cart items
//     const newCartItems = [...cartItems, ...order.items];
//     setCartItems(newCartItems);

//     // Save cart items and selected customer to local storage
//     localStorage.setItem("cartItems", JSON.stringify(newCartItems));
//     localStorage.setItem("selectedCustomer", JSON.stringify(order.customer));
//   };

//   const updateOrderStatus = (orderId, status) => {
//     setOrders((prevOrders) =>
//       prevOrders.map((order) =>
//         order.name === orderId
//           ? { ...order, return_order_status: status }
//           : order
//       )
//     );
//     GetSalesOrderList(PageCount); 
//   };
//   const handleDeleteOrder = (orderId) => {
//     const updatedOrders = orders.filter((order) => order.name !== orderId);
//     setOrders(updatedOrders);

//     const parkedOrders = fetchParkedOrders().filter(
//       (order) => order.name !== orderId
//     );
//     localStorage.setItem("parkedOrders", JSON.stringify(parkedOrders));
//   };

//   const filteredOrders = orders.filter(
//     (order) =>
//       (order.customer_name &&
//         order.customer_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
//       (order.contact_mobile && order.contact_mobile.includes(searchTerm))
//   );
  
//   useEffect(() => {
//     const { email } = JSON.parse(localStorage.getItem("user"));
//     fetchSearchSalesOrderList(email,PageCount,searchTerm).then((res) => {
//       console.log("SearchDataVioAPI",res,"searchTerm",searchTerm);
//       setSearchData(res)

//     })
//   }, [searchTerm,PageCount])
 
//   const handleTabSwitch=()=>{
//     setSearchTerm("")
//     setCurrentPageParked(1); 
//     setPageCount(1); 
//    }

  
   
  

//   // const categorizedOrders = {
//   //   parked: filteredOrders.filter((order) => order.status === "cartItems"),
//   //   failed: filteredOrders.filter(
//   //     (order) => order.return_order_status === "Failed"
//   //   ),
//   //   complete: filteredOrders.filter(
//   //     (order) =>
//   //       order.return_order_status === "Fully" ||
//   //       order.return_order_status === "Partially" ||
//   //       order.return_order_status === ""
//   //   ),
//   // };
//   const categorizedOrders = {
//     parked: filteredOrders.filter((order) => order.status === "cartItems"),
//     complete: filteredOrders.filter(
//       (order) =>
//         order.return_order_status === "Fully" ||
//         order.return_order_status === "Partially" ||
//         order.return_order_status === ""
//     ),
//   };
//   // Parked Orders Pagination
//   const indexOfLastOrderParked = currentPageParked * ordersPerPage;
//   const indexOfFirstOrderParked = indexOfLastOrderParked - ordersPerPage;
//   const currentOrdersParked = categorizedOrders.parked.slice(
//     indexOfFirstOrderParked,
//     indexOfLastOrderParked
//   );
//   const totalPagesParked = Math.ceil(
//     categorizedOrders.parked.length / ordersPerPage
//   );

//   // Complete Orders Pagination
//   const indexOfLastOrderComplete = currentPageComplete * ordersPerPage;
//   const indexOfFirstOrderComplete = indexOfLastOrderComplete - ordersPerPage;
//   const currentOrdersComplete = categorizedOrders.complete.slice(
//     indexOfFirstOrderComplete,
//     indexOfLastOrderComplete
//   );
//   const totalPagesComplete = Math.ceil(
//     categorizedOrders.complete.length / ordersPerPage
//   );

//   if (loading) {
//     return (
//       <div className="loading-spin">
//         <Spin tip="Loading..."></Spin>
//       </div>
//     );
//   }

//   return (
//     <Layout>
//       <div className="main-cont order-page">
//         <div className="heading-cont">
//           <h1 className="Orders-header">Orders</h1>
//           <div className="searchField">
//             <input
//               type="text"
//               placeholder="Search Order"
//               value={searchTerm}
//               onChange={handleSearchChange}
//               className="order-search"
//             />
//             <button>
//               <img src={SearchIcon} alt="Search" />
//             </button>
//           </div>
//         </div>
//         <Tabs defaultActiveKey="1">
//           <TabPane tab={<span className="tab-parked">Parked</span>} key="1">
//             <div className="tab-inner-cont content-parked">
//               {currentOrdersParked.length === 0 ? (
//                 <div className="no-data">No Parked order</div>
//               ) : (
//                 currentOrdersParked.map((order, index) => (
//                   <OrderBox
//                     key={index}
//                     order={order}
//                     showPayNow={false}
//                     showDelete={true}
//                     showMoveToCart={false}
//                     onClick={() => moveOrderToCart(order)}
//                     onDelete={() => handleDeleteOrder(order.name)}
//                     indicator={false}
//                   />
//                 ))
//               )}
//             </div>
//             <Pagination
//               currentPage={currentPageParked}
//               totalPages={totalPagesParked}
//               onPageChange={handlePageChangeParked}
//             />
//           </TabPane>
//           {/* 
//           <TabPane tab={<span className="tab-complete">Complete</span>} key="3">
//             <div className="tab-inner-cont content-complete">
//               {currentOrdersComplete.length === 0 ? (
//                 <div className="no-data">No Complete order</div>
//               ) : (
//                 currentOrdersComplete.map((order, index) => (
//                   <OrderBox
//                     key={index}
//                     order={order}
//                     showPayNow={false}
//                     showDelete={false}
//                     showMoveToCart={false}
//                     onClick={() => handleOrderClick(order)}
//                     indicator={true}
                  
//                   />
//                 ))
//               )}
//             </div>
//             <Pagination
//                     // totalItems={categorizedOrders.complete.length}
//                     // itemsPerPage={itemsPerPage}
//                     currentPage={currentPageComplete}
//               totalPages={totalPagesComplete}
//               onPageChange={handlePageChangeComplete}
//               />
//           </TabPane> */}

//           <TabPane tab={<span className="tab-complete">Complete</span>} key="3">
//             <div className="tab-inner-cont content-complete">
//               {Data.length === 0 || SearchData.length === 0 ? (
//                 <div className="no-data">No Complete order</div>
//               ) : searchTerm.length > 0 ? (
//                 SearchData.map((order, index) => (
//                   <OrderBox
//                     key={index}
//                     order={order}
//                     showPayNow={false}
//                     showDelete={false}
//                     showMoveToCart={false}
//                     onClick={handleOrderClick}
//                     indicator={true}
//                   />
//                 ))
//               ) : (
//                 Data.map((order, index) => (
//                   <OrderBox
//                     key={index}
//                     order={order}
//                     showPayNow={false}
//                     showDelete={false}
//                     showMoveToCart={false}
//                     onClick={handleOrderClick}
//                     indicator={true}
//                   />
//                 ))
//               )}
//             </div>

//             <div className="pagination">
//               <button
//                 className="prev"
//                 onClick={handlePrevBtn}
//                 disabled={PageCount === 1}
//               >
//                 Previous
//               </button>
//               <div className="page-number">{PageCount}</div>
//               <button
//                 className="next"
//                 onClick={handleNextBtn}
//                 disabled={Data.length === 0 || SearchData.length < 12 }
//               >
//                 Next
//               </button>
//             </div>
//             {/* ------------------------------------------------------ */}
//           </TabPane>
//         </Tabs>
//       </div>

//       <OrderDetailModal
//         visible={isModalVisible}
//         onClose={handleModalClose}
//         order={selectedOrder}
//         onUpdateOrder={updateOrderStatus}
//         // setIsReturnSuccess = {}
//         // setIsReturnPartial = {}
//       />
//     </Layout>
//   );
// };

// export default OrderPage;
import React, { useState, useEffect, useContext } from "react";
import { Spin, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import OrderBox from "../components/OrderBox";
import SearchIcon from "../assets/images/icon-search.png"; 
import {
  fetchSalesOrderList,
  fetchSearchSalesOrderList,
} from "../modules/LandingPage";
import OrderDetailModal from "../components/OrderDetailModal";
import { CartContext } from "../common/CartContext";
import Pagination from "../components/PaginationforOrdersPage";

const { TabPane } = Tabs;

const OrderPage = ({ hubManagerEmail }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const [searchTerm, setSearchTerm] = useState("");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [currentPageParked, setCurrentPageParked] = useState(1);
  const [currentPageComplete, setCurrentPageComplete] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(6);
  const [Data, setData] = useState([])
  const [SearchData, setSearchData] = useState([])
  const [PageCount, setPageCount] = useState(1);
  const { cartItems, setCartItems, red, setred } = useContext(CartContext);
  const [isReturnSuccess, setIsReturnSuccess] = useState(false);
  const [isReturnPartial, setIsReturnPartial] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const GetSalesOrderList = async (PageCount) => {
    try {
      const { email } = JSON.parse(localStorage.getItem("user"));
      const orderList = await fetchSalesOrderList(email, PageCount);
      setData(orderList);
      setLoading(false);
    } catch (error) {
      console.log("Error occurred", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetSalesOrderList(PageCount);
  }, [PageCount, hubManagerEmail, red]);

  const handlePrevBtn =async () => {
    setPageCount((prev) => prev - 1);
  };
  const handleNextBtn =async () => {
    setPageCount((prev) => prev + 1);
  };

  const fetchParkedOrders = () => {
    const parkedOrders = JSON.parse(localStorage.getItem("parkedOrders")) || [];
    return parkedOrders.map((order) => ({
      ...order,
      customer_name: order.customer_name || "Guest",
      contact_mobile: order.contact_mobile || "N/A",
    }));
  };
  const User = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderList = await fetchSalesOrderList(
          JSON.parse(localStorage.getItem("user")).email,
          currentPageComplete
        );
        const parkedOrders = fetchParkedOrders();

        // Only add parked orders once, and ensure no duplication
        const uniqueOrders = [...orderList, ...parkedOrders].reduce(
          (acc, order) => {
            if (!acc.some((o) => o.name === order.name)) {
              acc.push(order);
            }
            return acc;
          },
          []
        );

        setOrders(uniqueOrders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };
    fetchOrders();
  }, [hubManagerEmail, currentPageComplete, isReturnSuccess, isReturnPartial]);

  const handlePageChangeParked = (page) => {
    setCurrentPageParked(page);
  };

  const handlePageChangeComplete = (page) => {
    setCurrentPageComplete(page);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPageParked(1); // Reset to the first page when searching
    setPageCount(1);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  const moveOrderToCart = (order) => {
    // Remove the order from parked orders
    const updatedParkedOrders = fetchParkedOrders().filter(
      (o) => o.name !== order.name
    );
    localStorage.setItem("parkedOrders", JSON.stringify(updatedParkedOrders));

    // Update the orders state to remove the moved order
    const updatedOrders = orders.filter((o) => o.name !== order.name);
    setOrders(updatedOrders);

    // Add the order items to cart items
    const newCartItems = [...cartItems, ...order.items];
    setCartItems(newCartItems);

    // Save cart items and selected customer to local storage
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
    localStorage.setItem("selectedCustomer", JSON.stringify(order.customer));
  };

  const updateOrderStatus = (orderId, status) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.name === orderId
          ? { ...order, return_order_status: status }
          : order
      )
    );
    GetSalesOrderList(PageCount);
  };

  const handleDeleteOrder = (orderId) => {
    const updatedOrders = orders.filter((order) => order.name !== orderId);
    setOrders(updatedOrders);

    const parkedOrders = fetchParkedOrders().filter(
      (order) => order.name !== orderId
    );
    localStorage.setItem("parkedOrders", JSON.stringify(parkedOrders));
  };

  const filteredOrders = orders.filter(
    (order) =>
      (order.customer_name &&
        order.customer_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order.contact_mobile && order.contact_mobile.includes(searchTerm))
  );

  useEffect(() => {
    const { email } = JSON.parse(localStorage.getItem("user"));
    fetchSearchSalesOrderList(email, PageCount, searchTerm).then((res) => {
      console.log("SearchDataVioAPI",res,"searchTerm",searchTerm);
      setSearchData(res);
    });
  }, [searchTerm, PageCount]);

  const handleTabSwitch = () => {
    setSearchTerm("");
    setCurrentPageParked(1);
    setPageCount(1);
  };
  
  // const categorizedOrders = {
  //   parked: filteredOrders.filter((order) => order.status === "cartItems"),
  //   failed: filteredOrders.filter(
  //     (order) => order.return_order_status === "Failed"
  //   ),
  //   complete: filteredOrders.filter(
  //     (order) =>
  //       order.return_order_status === "Fully" ||
  //       order.return_order_status === "Partially" ||
  //       order.return_order_status === ""
  //   ),
  // }; 

  const categorizedOrders = {
    parked: filteredOrders.filter((order) => order.status === "cartItems"),
    complete: filteredOrders.filter(
      (order) =>
        order.return_order_status === "Fully" ||
        order.return_order_status === "Partially" ||
        order.return_order_status === ""
    ),
  };

  // Parked Orders Pagination
  const indexOfLastOrderParked = currentPageParked * ordersPerPage;
  const indexOfFirstOrderParked = indexOfLastOrderParked - ordersPerPage;
  const currentOrdersParked = categorizedOrders.parked.slice(
    indexOfFirstOrderParked,
    indexOfLastOrderParked
  );
  const totalPagesParked = Math.ceil(
    categorizedOrders.parked.length / ordersPerPage
  );

  // Complete Orders Pagination
  const indexOfLastOrderComplete = currentPageComplete * ordersPerPage;
  const indexOfFirstOrderComplete = indexOfLastOrderComplete - ordersPerPage;
  const currentOrdersComplete = categorizedOrders.complete.slice(
    indexOfFirstOrderComplete,
    indexOfLastOrderComplete
  );
  const totalPagesComplete = Math.ceil(
    categorizedOrders.complete.length / ordersPerPage
  );

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin tip={t("orderPage.loading")}></Spin>
      </div>
    );
  }

  return (
    <Layout>
      <div className="main-cont order-page">
        <div className="heading-cont">
          <h1 className="Orders-header">{t("orderPage.ordersHeader")}</h1>
          <div className="searchField">
            <input
              type="text"
              placeholder={t("orderPage.searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearchChange}
              className="order-search"
            />
            <button>
              <img src={SearchIcon} alt={t("orderPage.searchButtonAlt")} />
            </button>
          </div>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab={<span className="tab-parked">{t("orderPage.tabParked")}</span>} key="1">
            <div className="tab-inner-cont content-parked">
              {currentOrdersParked.length === 0 ? (
                <div className="no-data">{t("orderPage.noDataParked")}</div>
              ) : (
                currentOrdersParked.map((order, index) => (
                  <OrderBox
                    key={index}
                    order={order}
                    showPayNow={false}
                    showDelete={true}
                    showMoveToCart={false}
                    onClick={() => moveOrderToCart(order)}
                    onDelete={() => handleDeleteOrder(order.name)}
                    indicator={false}
                  />
                ))
              )}
            </div>
            <Pagination
              currentPage={currentPageParked}
              totalPages={totalPagesParked}
              onPageChange={handlePageChangeParked}
            />
          </TabPane>
           {/* 
          <TabPane tab={<span className="tab-complete">Complete</span>} key="3">
            <div className="tab-inner-cont content-complete">
              {currentOrdersComplete.length === 0 ? (
                <div className="no-data">No Complete order</div>
              ) : (
                currentOrdersComplete.map((order, index) => (
                  <OrderBox
                    key={index}
                    order={order}
                    showPayNow={false}
                    showDelete={false}
                    showMoveToCart={false}
                    onClick={() => handleOrderClick(order)}
                    indicator={true}
                  
                  />
                ))
              )}
            </div>
            <Pagination
                    // totalItems={categorizedOrders.complete.length}
                    // itemsPerPage={itemsPerPage}
                    currentPage={currentPageComplete}
              totalPages={totalPagesComplete}
              onPageChange={handlePageChangeComplete}
              />
          </TabPane> */}
          <TabPane tab={<span className="tab-complete">{t("orderPage.tabComplete")}</span>} key="3">
            <div className="tab-inner-cont content-complete">
              {Data.length === 0 || SearchData.length === 0 ? (
                <div className="no-data">{t("orderPage.noDataComplete")}</div>
              ) : searchTerm.length > 0 ? (
                SearchData.map((order, index) => (
                  <OrderBox
                    key={index}
                    order={order}
                    showPayNow={false}
                    showDelete={false}
                    showMoveToCart={false}
                    onClick={handleOrderClick}
                    indicator={true}
                  />
                ))
              )  : (
                Data.map((order, index) => (
                  <OrderBox
                    key={index}
                    order={order}
                    showPayNow={false}
                    showDelete={false}
                    showMoveToCart={false}
                    onClick={handleOrderClick}
                    indicator={true}
                  />
                ))
              )}
            </div>

            <div className="pagination">
              <button
                className="prev"
                onClick={handlePrevBtn}
                disabled={PageCount === 1}
              >
                {t("orderPage.prevButton")}
              </button>
              <div className="page-number">{PageCount}</div>
              <button
                className="next"
                onClick={handleNextBtn}
                disabled={Data.length === 0 || SearchData.length < 12 }
              >
                {t("orderPage.nextButton")}
              </button>
            </div>
            {/* ------------------------------------------------------ */}
          </TabPane>
        </Tabs>
      </div>

      <OrderDetailModal
        visible={isModalVisible}
        onClose={handleModalClose}
        order={selectedOrder}
        onUpdateOrder={updateOrderStatus}
        // setIsReturnSuccess = {}
        // setIsReturnPartial = {}
      />
    </Layout>
  );
};

export default OrderPage;
