// import React from "react";
// import PropTypes from "prop-types";

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//   return (
//     <div className="pagination">
//       <button
//         className="prev"
//         onClick={() => onPageChange("prev")}
//         disabled={currentPage === 1}
//       >
//         Previous
//       </button>
//       <div className="page-number">{currentPage}</div>
//       <button
//         className="next"
//         onClick={() => onPageChange("next")}
//         disabled={currentPage === totalPages}
//       >
//         Next
//       </button>
//     </div>
//   );
// };

// Pagination.propTypes = {
//   currentPage: PropTypes.number.isRequired,
//   totalPages: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
// };

// export default Pagination;

// import React from 'react';
// import PropTypes from 'prop-types';
// import '../components/style.css';

// const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
//     const totalPages = Math.ceil(totalItems / itemsPerPage);

//     const handlePageChange = (pageNumber) => {
//         onPageChange(pageNumber);
//     };
   
//     return (
//         <div className="pagination">
//             <button
//                 className="prev"
//                 onClick={() => handlePageChange(currentPage - 1)}
//                 disabled={currentPage === 1 || totalItems === 0}
//             >
//                 Previous
//             </button>
//             <div className="page-number">{currentPage}</div>
//             <button
//                 className="next"
//                 onClick={() => handlePageChange(currentPage + 1)}
//                 disabled={currentPage === totalPages || totalItems === 0}
//             >
//                 Next
//             </button>
//         </div>
//     );
// };

// Pagination.propTypes = {
//     totalItems: PropTypes.number.isRequired,
//     itemsPerPage: PropTypes.number.isRequired,
//     currentPage: PropTypes.number.isRequired,
//     onPageChange: PropTypes.func.isRequired,
// };

// export default Pagination;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import '../components/style.css';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    const { t } = useTranslation(); // Use useTranslation

    // console.log("total Items: " + totalItems);
    // console.log("itemsPerPage: " +itemsPerPage);
    

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // console.log("Items per page: in pagination :  " + totalPages)

    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
    };
   
    return (
        <div className="pagination">
            <button
                className="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || totalItems === 0}
            >
                {t('pagination.previous')} {/* Use translation */}
            </button>
            <div className="page-number">{currentPage}</div>
            <button
                className="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalItems === 0}
            >
                {t('pagination.next')} {/* Use translation */}
            </button>
        </div>
    );
};

Pagination.propTypes = {
    totalItems: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
